import styled, { css } from "styled-components"
import { darken } from "polished"

type ItemType = {
  isActive: boolean
}


export const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
`


export const Nav = styled.header<any>(
  ({
    theme: {
      variables: { primary, spacing },
      awesomegrid,
    },
    open,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    background-color: ${primary.black};
    padding: 0 40px;
    position: relative;
    z-index: 9999;
    width: 100%;

    @media (max-width: ${awesomegrid.breakpoints.md}rem) {
      position: ${open ? "fixed" : "flex"};
      justify-content: space-between;
    }
  `
)

export const Burger = styled.div<any>(
  ({
    theme: {
      variables: { primary, spacing },
      awesomegrid,
    },
    open,
  }) => css`
    display: none;

    @media (max-width: ${awesomegrid.breakpoints.md - 0.01}rem) {
      display: flex;
    }
    svg {
      z-index: 9999;
      transition: all 0.3s linear;
      cursor: pointer;
      &:nth-child(1) {
        transform: ${open ? "rotate(360deg)" : "rotate(0)"};
      }
    }
  `
)

export const Image = styled.div(
  ({
    theme: {
      variables: { primary, spacing },
      awesomegrid,
    },
  }) => css`
    height: 64px;
    width: 64px;
    object-fit: contain;
    cursor: pointer;

    @media (min-width: ${awesomegrid.breakpoints.md}rem) {
      margin-right: 46px;
    }

    @media (min-width: ${awesomegrid.breakpoints.lg}rem) {
      margin-right: 42px;
    }
  `
)

export const Items = styled.ul<any>(
  ({
    theme: {
      variables: { primary, spacing },
      awesomegrid,
    },
    open,
  }) => css`
    display: none;

    @media (max-width: ${awesomegrid.breakpoints.md}rem) {
      left: 0;
      right: 0;
      top: 75px;
      bottom: 0;
      z-index 999;
      display: flex;
      width: 100%;
      height: 100%;
      position: fixed;
      padding-left: 40px;
      padding-top: 60px;
      flex-flow: column nowrap;
      background-color: ${primary.black};
      transform: ${open ? "translateX(0)" : "translateX(100%)"};
      transition: transform 0.3s ease-in-out;
      li {
        &:not(:first-child) {
          margin-top: 66px;
        }
        &:hover {
          
        }

        svg {
          margin-left: 4px;
        }
      }
    }
  `
)

export const Item = styled.li(
  ({
    theme: {
      variables: { primary, secondary },
    },
    isActive
  }) => css<ItemType>`
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    padding: 0px;
    color: #fff;

    a {
      color: #fff;
      text-decoration: ${isActive ? 'underline' : 'none'};

      &:hover {
        text-decoration: underline;
      }
    }
  `
)

export const NavItems = styled.ul<any>(
  ({
    theme: {
      variables: { primary, spacing },
      awesomegrid,
    },
    open,
  }) => css`
    display: none;

    @media (min-width: ${awesomegrid.breakpoints.md}rem) {
      display: flex;
      align-items: center;

      li {
        &:not(:last-child) {
          margin-right: 32px;
        }
        &:hover {
        }

        svg {
          margin-left: 4px;
        }
      }
    }
  `
)

export const Dropdown = styled.ul(
  ({
    theme: {
      variables: { primary, spacing },
      awesomegrid,
    },
  }) => css`
    // display: none;
    position: relative;
    background: ${primary.black};
    padding: 20px;
    z-index: 99999;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    left: -40px;
    cursor: initial;
    

    @media (min-width: ${awesomegrid.breakpoints.md}rem) {
      position: absolute;
      animation: fadein 0.1s;
    }

    @media (max-width: ${awesomegrid.breakpoints.md}rem) {
      min-width: 80vw;
    }

    animation: fadein 0.5s;

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `
)

export const HeaderLinks = styled.header(
  ({
    theme: {
      variables: { primary },
      awesomegrid
    },
  }) => css`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    
    a {
      padding: 10px 10px;
      color: ${primary.white};
    }
  `
)

export const ImageWrapper = styled.a(
  ({
    theme: {
      variables: { primary },
    },
  }) => css`
    padding: 0;
    margin: 0;
    display: flex;
  `
)
