import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { Link } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import Icon from "../Icons"

import Logo from "../../images/footer/white_logo.svg"

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.variables.primary.black};
  padding: 96px 0;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 80px 0;
  }
`

const FooterLogoWrapper = styled.div`
  display: flex;
  // margin: auto;
  margin-bottom: 80px;
  justify-content: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    justify-content: flex-end;
  }
`

const Icons = styled.div`
  display: flex;
  // margin: auto;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    justify-content: flex-start;
  }
`

const IconWrapper = styled.div`
  background: ${props => props.theme.variables.secondary.yellow};
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-6px);
  }

  &:not(:last-child) {
    margin-right: 16px;
  }

  a {
    svg {
      fill: ${props => props.theme.variables.primary.black};
    }
  }
`

const SubLinks = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-left: 0;
    justify-content: flex-start;
  }
`

const SubLink = styled.li`
  &:not(:last-child) {
    margin-right: 40px;
  }
  a {
    color: #f1ede5;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.03em;
  }
`

const SubLinkIcon = styled.div`
  cursor: pointer;
  margin-top: 80px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 32px;
  }

  a {
    color: ${props => props.theme.variables.forge.cream};
    font-weight: bold;
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: 0.03em;
    margin-right: 4px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 1px;
    }
  }
`

const Rights = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: ${props => props.theme.variables.secondary.gray};
  display: flex;
  text-align: center;
  letter-spacing: 0.03em;
  margin: 80px 0;
  flex-direction: column;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 70%;
    text-align: left;
  }
`

const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const Footer = () => {

  return (
    <FooterWrapper>
      <Container>
        <Row
          reverse={["xs", "sm"]}
          justify={{ xs: "center", md: "space-between" }}
        >
          <Col xs={2} sm={8} md={5} lg={6}>
            <Icons>
              <IconWrapper>
                <a href="https://twitter.com/totallychoice" target="_blank">
                  <Icon icon="twitter" size={22} />
                </a>
              </IconWrapper>
              <IconWrapper>
                <a href="https://www.instagram.com/choiceprovisions/" target="_blank">
                  <Icon icon="instagram" size={22} />
                </a>
              </IconWrapper>
              <IconWrapper>
                <a href="https://www.youtube.com/user/ChoiceProvisions" target="_blank">
                  <Icon icon="youtube" size={22} />
                </a>
              </IconWrapper>
              <IconWrapper>
                <a href="https://www.facebook.com/ChoiceProvisions/" target="_blank">
                  <Icon icon="facebook" size={22} />
                </a>
              </IconWrapper>
            </Icons>
            <SubLinks>
              {/*
              <SubLinkIcon>
                <a>Careers</a>
                <Icon icon="arrow-link" size={16} color="#fff" />
              </SubLinkIcon>
              */}
              <SubLinkIcon>
                <a href="mailto:support@totallychoice.com" target="_blank">Support</a>
                <Icon icon="arrow-link" size={16} color="#fff" />
              </SubLinkIcon>
            </SubLinks>

            <Rights>
              <span>© 2021 Choice Provisions, Inc.</span>
              <span>
                Questions?
                Feel free to contact us at{' '}
                <StyledA href="mailto:support@totallychoice.com">support@totallychoice.com</StyledA>

              </span>
            </Rights>
            {/*
            <SubLinks>
              <SubLink>
                <a href="#">Privacy Notice</a>
              </SubLink>
              <SubLink>
                <a href="#">Terms of Service</a>
              </SubLink>
            </SubLinks>
            */}
          </Col>

          <Col xs={2} sm={8} md={3} lg={6}>
            <FooterLogoWrapper>
              <Link to="/">
                <Logo />
              </Link>
            </FooterLogoWrapper>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
