/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
// @ts-ignore
import lax from "lax.js"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import {
  ThemeProvider,
  StyleSheetManager,
  createGlobalStyle,
} from "styled-components"
import rtlcss from "stylis-rtlcss"

import Header from "./Header"
import Footer from "./Footer"

import { normalize } from "./normalize"
import { Helmet } from "react-helmet"

import { ScreenBadge } from "react-awesome-styled-grid"

import "../styles/index.css"

const customConf = {
  mediaQuery: "only screen",
  columns: {
    xs: 2,
    sm: 2, //mobile 320 - 768
    md: 8, //Tablet 768 - 1440
    lg: 12, //Laptop 1440 - 2560
    xl: 12, //Desktop min 2560
  },
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 1.25,
    lg: 1.25,
    xl: 1.25,
  },
  paddingWidth: {
    // container sağ/sol padding
    xs: 1.5,
    sm: 1.5,
    md: 1.5,
    lg: 1.5,
    xl: 1.5,
  },
  container: {
    xs: 46, //  max-width: 736px
    sm: 46, // max-width: 736px
    md: 60, // max-width: 960px
    lg: 76.875, // max-width: 1230px
    xl: 76.875, // max-width: 1230px
  },

  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 62.5, // 1000px
    lg: 90, // 1440px
    xl: 160, // 2560p
  },
}

const variables = {
  primary: {
    white: "#FFFFFF",
    black: "#111111",
  },
  secondary: {
    orange: "#FFA632",
    yellow: "#FBDA03",
    blue: "#35A6AA",
    pink: "#DC6CA7",
    purple: "#363579",
    gray: "#808080",
  },
  forge: {
    neutral: "#F5F5F5;",
    cream: "#F1EDE5",
  },
  spacing: {
    xsmall: "0.25rem",
    small: "0.5rem",
    normal: "1rem",
    large: "1.5rem",
    xlarge: "2rem",
  },
  icon: {
    xsmall: "0.5rem",
    small: "1rem",
    normal: "1.5rem",
    large: "2rem",
  },
}

// @ts-ignore
const GlobalStyle = createGlobalStyle(normalize)

type Props = {
  children: any
}

const Layout: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    lax.init()

    lax.addDriver("scrollY", function () {
      return window.scrollY
    })

    // Add your elements
    lax.addElements(".animation-hero", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY", "elOutY"],
          ["elHeight", 0, "-elHeight"],
        ],
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 0.5],
        ],
      },
    })
    lax.addElements(".animation-opacity", {
      scrollY: {
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 1],
        ],
      },
    })
    lax.addElements(".animation-left", {
      scrollY: {
        translateX: [
          ["elInY", "elCenterY", "elOutY"],
          ["-elWidth", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 1],
        ],
      },
    })
    lax.addElements(".animation-right", {
      scrollY: {
        translateX: [
          ["elInY", "elCenterY", "elOutY"],
          ["elWidth", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 1],
        ],
      },
    })
    lax.addElements(".animation-up", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY-200", "elOutY"],
          ["elHeight/2", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY-100", "elOutY"],
          [0.5, 1, 1],
        ],
      },
    })
    lax.addElements(".animation-up-gallery", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY-elHeight/2", "elOutY"],
          ["elHeight/2", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY-elHeight/2", "elOutY"],
          [0.5, 1, 1],
        ],
      },
    })
    lax.addElements(".animation-height", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY", "elOutY"],
          ["elHeight", 0, 0],
        ],
      },
    })

    return () => {
      lax.removeDriver("scrollY")
    }
  }, [children])
  //rtlcss
  return (
    <>
      <ThemeProvider theme={{ awesomegrid: customConf, variables }}>
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/iaw7mae.css" />
        </Helmet>
        <GlobalStyle />
        <StyleSheetManager stylisPlugins={[]}>
          <>
            <Header />
            <main>{children}</main>
            <Footer />
            {/* <ScreenBadge /> */}
          </>
        </StyleSheetManager>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
