import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import Logo from "../../images/header/logo.svg"
import Icon from "../Icons"
import Button from "../Commons/Button"

import {
  Image,
  Nav,
  Burger,
  Items,
  Item,
  NavItems,
  Dropdown,
  HeaderLinks,
  ImageWrapper,
  StyledA,
} from "./styled"

const Header = () => {
  const { t } = useTranslation()
  // const [list, setList] = useState([
  //   {
  //     title: "Games",
  //     dropdown: [
  //       {
  //         title: "Dropdown 1",
  //         href: '/'
  //       },
  //       {
  //         title: "Dropdown 2",
  //         href: '/'
  //       },
  //     ]
  //   },
  //   {
  //     title: "Social",
  //     dropdown: [
  //       {
  //         title: "Dropdown 1",
  //         href: '/'
  //       },
  //       {
  //         title: "Dropdown 2",
  //         href: '/'
  //       },
  //     ]
  //   },
  //   {
  //     title: "About"
  //   }
  // ])

  const [open, setOpen] = useState(false)
  const [gamesDropdown, setGamesDropdown] = useState(false)
  const [socialDropdown, setSocialDropdown] = useState(false)

  const hasWindow = typeof window !== 'undefined';

  return (
    <Nav open={open}>
      <ImageWrapper href="/">
        <Image>
          <Logo />
        </Image>
      </ImageWrapper>
      <Burger open={open} onClick={() => setOpen(!open)}>
        <Icon icon={open ? "close" : "sandwich"} color="#fff" size={32} />
      </Burger>

      <NavItems>
        <Item isActive={hasWindow && window.location.pathname.includes('games-library')}>
          <Link to="/games-library">games</Link>
        </Item>
        <Item isActive={false} onMouseEnter={() => setSocialDropdown(true)} onMouseLeave={() => setSocialDropdown(false)} >
          <a>social</a>
          <Icon icon={"chevron"} color="#fff" size={16} />
          {socialDropdown && (
            <Dropdown>
              <HeaderLinks>
                <Link to="https://twitter.com/totallychoice?lang=en" target="_blank">Twitter</Link>
                <Link to="https://www.facebook.com/ChoiceProvisions/" target="_blank">Facebook</Link>
                <Link to="https://www.instagram.com/choiceprovisions/" target="_blank">Instagram</Link>
              </HeaderLinks>
            </Dropdown>
          )}
        </Item>
        <Item isActive={hasWindow && window.location.pathname.includes('about')}>
          <Link to="/about">about</Link>
        </Item>

        <StyledA href="/#newsletter">
          <Button type="secondary" size="small">
            Newsletter Signup
          </Button>
        </StyledA>
      </NavItems>
      <Items open={open}>
        <Item isActive={hasWindow && window.location.pathname.includes('games-library')}>
          <Link to="/games-library">games</Link>
        </Item>
        <Item isActive={false} onClick={() => setSocialDropdown(!socialDropdown)}>
          <Link to="/">social</Link>
          <Icon icon={"chevron"} color="#fff" size={16} />
          {socialDropdown ? (
            <Dropdown>
              <HeaderLinks>
                <Link to="https://twitter.com/totallychoice?lang=en" target="_blank">Twitter</Link>
                <Link to="https://www.facebook.com/ChoiceProvisions/" target="_blank">Facebook</Link>
                <Link to="https://www.instagram.com/choiceprovisions/" target="_blank">Instagram</Link>
              </HeaderLinks>
            </Dropdown>
          ) : null}
        </Item>
        <Item isActive={hasWindow && window.location.pathname.includes('about')}>
          <Link to="/about">about</Link>
        </Item>
      </Items>
    </Nav>
  )
}

export default Header
