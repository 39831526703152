import React, { Children } from "react"
import styled from "styled-components"
import { darken, lighten } from "polished"
// import PropTypes from 'prop-types';

interface BTN {
  type: "primary" | "secondary" | "tertiary"
  size?: "small"
}

type BTN_TYPE = {
  type: string
  size: string
  isLight: boolean
}

const Module = styled.button<BTN_TYPE>`
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;

  margin: 0;
  border: none;
  outline: none;
  padding: 16px 32px;

  color: ${props =>
    !props.isLight
      ? props.theme.variables.primary.black
      : props.theme.variables.primary.white};
  font-size: 16px;
  font-weight: 800;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: ${props => (props.size === "small" ? "14px" : "24px")};
  }
`

const PrimaryButton = styled(Module)`
  background-color: ${props => props.theme.variables.secondary.yellow};

  &:hover {
    background-color: ${props =>
      darken(0.02, props.theme.variables.secondary.yellow)};
  }
  &:active {
    background-color: ${props =>
      darken(0.05, props.theme.variables.secondary.yellow)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const SecondaryButton = styled(Module)`
  background-color: ${props => props.theme.variables.primary.white};

  &:hover {
    background-color: ${props =>
      darken(0.06, props.theme.variables.primary.white)};
  }
  &:active {
    background-color: ${props =>
      darken(0.1, props.theme.variables.primary.white)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

type Tertiary_Types = {
  isLight: boolean
}

const TertiaryButton = styled(Module)<Tertiary_Types>`
  background-color: transparent;
  padding: 14px 32px;
  border: 2px solid ${props => props.theme.variables.secondary.yellow};

  color: ${props =>
    !props.isLight
      ? props.theme.variables.primary.black
      : props.theme.variables.primary.white};

  &:hover {
    background-color: ${props => props.theme.variables.secondary.yellow};
    color: ${props => props.theme.variables.primary.black};
  }
  &:active {
    background-color: ${props =>
      darken(0.05, props.theme.variables.secondary.yellow)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

type Props = {
  onClick?: () => void
  className?: string
  children: React.ReactNode
  type: "primary" | "secondary" | "tertiary"
  button_type?: "button" | "submit"
  size?: "small"
  isLight?: boolean
}

function Button(props: Props) {
  let Component: any

  if (props.type === "primary") {
    Component = PrimaryButton
  } else if (props.type === "secondary") {
    Component = SecondaryButton
  } else if (props.type === "tertiary") {
    Component = TertiaryButton
  } else {
    throw new Error(`Unrecognized Button variant: ${props.type}`)
  }

  return (
    <Component {...props} type={props.button_type}>
      {Children.toArray(props.children)}
    </Component>
  )
}

export default Button
